<template>
  <div>
    <h2 class="center">{{ portfolioName }}</h2>

    <multi-search-select
      id="multiple-sites"
      name="sites"
      :key="portfolioSiteFormKey"
      :options="remainingSites"
      :defaults="multiSelectDefaults"
      :minimum="minimumSites"
      sort-by="title"
      label="Select Sites"
      :showValidationError="!isValidSitesSelection"
      v-model="selectedSites">
    </multi-search-select>

    <p v-show="!submitting" class="form__submission">
      <button @click="updatePortfolio" class="button--outline margin-space-right" :class="{ 'button--disabled': !formComplete }" :disabled="!this.formComplete">Add</button>
      <button @click="cancel" class="button--underline">Cancel</button>
    </p>

    <i v-show="submitting" class="icon--spinner"></i>
  </div>
</template>

<script>
  import MultiSearchSelect from '../form_fields/MultiSearchSelect'
  import { mixinFormPortfolio } from '../../mixins/mixin-form-portfolio'
  import { eventHub } from '../../ibat.js'
  import helpers from '../../utilities/helpers.js'
  import axios from 'axios'

  export default {
    name: 'form-portfolio-sites',

    mixins: [mixinFormPortfolio],

    components: {
      MultiSearchSelect
    },

    props: {
      formFields: { required: true, type: Object },
      defaultSite: { type: Object, required: false }
    },

    created() {
      eventHub.$on('modalClosed', this.resetForm)
    },

    data() {
      return {
        selectedSites: [],
        minimumSites: 1,
        portfolioSiteFormKey: 0,
        remainingSites: []
      }
    },

    mounted() {
      this.businessId = document.body.dataset.businessId

      if (this.formFields.remaining_sites.length > 0) {
        this.remainingSites = this.formFields.remaining_sites
      }
    },

    computed: {
      portfolioName() {
        return this.formFields.name
      },

      isValidSitesSelection() {
        return this.isValidLength(this.selectedSites, this.minimumSites)
      },

      multiSelectDefaults() {
        return this.defaultSite ? [this.defaultSite] : [];
      },

      formComplete() {
        return this.isValidSitesSelection
      },

      updatePortfolioSiteParams() {
        return {
          site_ids: [...this.selectedSites.map(site => site.id), ...this.formFields.sites.map(site => site.id)]
        }
      }
    },

    methods: {
      resetForm() {
        this.portfolioSiteFormKey += 1

        const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
        axios.defaults.headers.common['X-CSRF-Token'] = csrf
        axios.defaults.headers.common['Accept'] = 'application/json'

        axios.get(`/b/${this.businessId}/portfolios/${this.formFields.id}`)
        .then((response) => {
          this.remainingSites = response.data.remaining_sites
          this.formFields.sites = response.data.sites
        })
        .catch(function (error) {
          console.log(error)
        })
      }
    }
  }
</script>
