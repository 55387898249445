<template>
  <div>
    <modal :closeable="false" :modal-id="modalId" modal-classes="modal--stripe">
      <h1 class="stripe--modal-title">Payment Summary</h1>
      <div class="stripe--modal-breakdown--wrapper">
        <div class="stripe--modal-breakdown--row">
          <p class="no-transform">Download:</p>
          <p class="no-transform">{{ humanReadable(priceExcludingVatInDollars) }}</p>
        </div>
        <div class="stripe--modal-breakdown--row">
          <p class="no-transform">VAT:</p>
          <p class="no-transform">{{ humanReadable(vatToCharge) }}</p>
        </div>
        <hr class="stripe--modal-hr">
        <div class="stripe--modal-breakdown--row">
          <h1 class="stripe--modal-total">Total:</h1>
          <h1 class="stripe--modal-total">{{ humanReadable(totalToCharge) }}</h1>
        </div>
      </div>
      <div class="flex flex-h-end">
        <button @click="cancelPayment" class="button--cta margin-space-right">Cancel</button>
        <button @click="confirmPayment" class="button--cta">Pay</button>
      </div>
    </modal>

    <div class="box-grey box-margin-top box-margin-bottom box-padding flex flex-v-center flex-h-center">
      <div class="stripe--form-wrapper flex flex-h-center flex-v-center">
        <div v-show="!isCreatingPayment" class="flex-1">
          <h1 class="center margin-space-bottom no-transform no-transform">Continue with 'Pay As You Go'</h1>
          <h1 class="center margin-space-bottom no-transform no-transform">Total</h1>
          <h1 class="center margin-space-bottom text-24">{{ humanReadable(priceExcludingVatInDollars) }}</h1>
          <p class="center margin-space-bottom light text-14">If you are based in the UK 20% VAT will apply</p>
          <label class="text-16 left">Email address</label>
          <input type="email" disabled="true" v-model="email" class="margin-space-bottom">
          <label class="text-16 left margin-space-top">Card number</label>
          <div id="card-number-element" class="stripe--card-element margin-space-bottom"></div>

          <div class="flex flex-row margin-space-bottom">
            <div class="flex-1 margin-space-right">
              <label class="text-16 left margin-space-top">Expiry date</label>
              <div id="card-expiry-element" class="stripe--card-element"></div>
            </div>
            <div class="flex-1 margin-space-left">
              <label class="text-16 left margin-space-top">CVC</label>
              <div id="card-cvc-element" class="stripe--card-element"></div>
            </div>
          </div>

          <p v-if="errorMessage" class="text-red bold">{{ errorMessage }}</p>
          <button @click="pay" :disabled="!isCardFormComplete" class="margin-space-top margin-space-bottom full-width" :class="classForFormStatus">Submit Payment</button>
        </div>
        <div v-show="isCreatingPayment" class="flex-1">
          <div class="center">
            <loader :width="100"></loader>
            <h2>We are currently processing your payment, please do not refresh the page.</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { eventHub } from '../../ibat.js'
  import Modal from '../modal/Modal.vue'
  import Loader from '../loading/Loader.vue'

  export default {
    name: 'stripe-payment',

    components: { Modal, Loader },

    props: {
      stripeKey: { required: true },
      purchasableType: { required: true },
      purchasableId: { required: true },
      email: { required: true },
      priceInCents: { required: true },
      vatInCents: { required: true },
      priceIncVatInCents: { required: true },
      inputErrorMessage: { required: false },
    },

    computed: {
      classForFormStatus() {
        let buttonClass = "button--cta"

        if (!this.isCardFormComplete) { buttonClass += " button--disabled" }

        return buttonClass
      },

      priceExcludingVatInDollars() {
        return this.inDollars(this.priceInCents);
      },

      priceIncludingVatInDollars() {
        return this.inDollars(this.priceIncVatInCents);
      },

      vatInDollars() {
        return this.inDollars(this.vatInCents);
      },

      vatToCharge() {
        return this.isPayingFromBritishAccount ? this.vatInDollars : 0;
      },

      totalToCharge() {
        if (this.isPayingFromBritishAccount) {
          return this.priceIncludingVatInDollars;
        } else {
          return this.priceExcludingVatInDollars;
        }
      },

      isPayingFromBritishAccount() {
        return this.paymentRequest && (this.paymentRequest.paymentMethod.card.country === 'GB');
      }
    },

    data() {
      return {
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
        stripe: Stripe(this.stripeKey),
        errorMessage: null,
        isCardFormComplete: false,
        isCardNumberComplete: false,
        isCardExpiryComplete: false,
        isCardCvcComplete: false,
        cardElementLookup: {
          cardNumber: 'isCardNumberComplete',
          cardExpiry: 'isCardExpiryComplete',
          cardCvc: 'isCardCvcComplete'
        },
        isCreatingPayment: false,
        defaultErrorMessage: 'The payment service is currently unavailable, please try again',
        cancellationMessage: 'You cancelled the payment',
        paymentRequest: null,
        modalId: 'stripe-confirmation'
      }
    },

    mounted() {
      this.businessId = document.body.dataset.businessId;
      this.mountStripeCardElement();
      this.populateAxiosHeaders();
      this.populateCardErrorMessage(this.inputErrorMessage);
    },

    methods: {

      confirmPayment() {
        eventHub.$emit('requestModalClose', this.modalId)
        this.makePaymentRequest(this.paymentRequest)
      },

      humanReadable(priceInDollars) {
        return priceInDollars.toLocaleString("en-US", { style:"currency", currency:"USD" });
      },

      mountStripeCardElement () {
        const elements = this.stripe.elements()

        // Styles must be injected as Stripe elements sit within <iframe> tags
        const elementStyles = {
          base: {
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 300
          }
        }

        this.cardNumber = elements.create(
          'cardNumber',
          { style: elementStyles }
        )
        this.cardExpiry = elements.create(
          'cardExpiry',
          { style: elementStyles }
        )
        this.cardCvc = elements.create(
          'cardCvc',
          { style: elementStyles }
        )

        this.cardNumber.mount('#card-number-element')
        this.cardExpiry.mount('#card-expiry-element')
        this.cardCvc.mount('#card-cvc-element')

        this.cardNumber.on('ready', () => this.cardNumber.focus() );
        this.addValidityListeners();
      },

      populateAxiosHeaders () {
        const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
        axios.defaults.headers.common['X-CSRF-Token'] = csrf
        axios.defaults.headers.common['Accept'] = 'application/json'
      },

      pay() {
        this.togglePaymentCreation();

        this.stripe.createPaymentMethod('card', this.cardNumber)
          .then((res) => {
            if (res.error) {
              this.returnToPaymentForm(res.error.message)
            } else {
              this.askForConfirmation(res)
            }
          })
          .catch((_error) => this.returnToPaymentForm())
      },

      askForConfirmation(result) {
        this.paymentRequest = result;
        eventHub.$emit('requestModalOpen', this.modalId)
      },

      cancelPayment() {
        this.paymentRequest = null;
        eventHub.$emit('requestModalClose', this.modalId)
        this.returnToPaymentForm(this.cancellationMessage)
      },

      makePaymentRequest(result) {
        if (result.error) {
          const errorMessage = result.error.message

          this.returnToPaymentForm(errorMessage);
        } else {
          const paymentOptions = {
            payment_method_id: result.paymentMethod.id,
            payment_country: result.paymentMethod.card.country,
            purchasable_type: this.purchasableType,
            purchasable_id: this.purchasableId
          }

          axios.post(`/b/${this.businessId}/purchases`, paymentOptions)
            .then(this.handleServerResponse)
            .catch((error) => {
              const errorMessage = error.response.data.error;

              this.returnToPaymentForm(errorMessage);
            })
        }
      },

      handleServerResponse(response) {
        const data = response.data

        if (data.requires_action) {
          this.handleCardAction(data)
        } else if (data.success) {
          window.location.href = data.redirect_url
        }
      },

      handleCardAction(data) {
        this.stripe.handleCardAction(data.payment_intent_client_secret)
          .then((result) => {
            if (result.error) {
              const errorMessage = result.error.message

              this.returnToPaymentForm(errorMessage);
            } else {
              const intentOptions = {
                payment_intent_id: result.paymentIntent.id,
                purchasable_type: this.purchasableType,
                purchasable_id: this.purchasableId
              }

              axios.post(`/b/${this.businessId}/purchases`, intentOptions)
                .then(this.handleServerResponse)
                .catch((error) => {
                  const errorMessage = error.response.data.error;

                  this.returnToPaymentForm(errorMessage);
                });
            }
          })
          .catch((_error) => this.returnToPaymentForm());
      },

      addValidityListeners() {
        const cardElements = Object.keys(this.cardElementLookup);

        cardElements.forEach((cardElement) => {
          this[cardElement]['on']('change', (event) => {
            let elementCompletionStatus = this.cardElementLookup[cardElement]
            this[elementCompletionStatus] = event.complete

            this.checkIfCardFormComplete();
          });
        });
      },

      populateCardErrorMessage(errorMessage) {
        this.errorMessage = errorMessage;
      },

      checkIfCardFormComplete() {
          this.isCardFormComplete = this.isCardNumberComplete && this.isCardExpiryComplete && this.isCardCvcComplete
      },

      togglePaymentCreation() {
        this.isCreatingPayment = !this.isCreatingPayment;
      },

      returnToPaymentForm(errorMessage = this.defaultErrorMessage) {
        this.togglePaymentCreation();
        this.clearPaymentForm();
        this.populateCardErrorMessage(errorMessage);
        setTimeout(() => this.cardNumber.focus(), 100)
      },

      clearPaymentForm() {
        const cardElements = Object.keys(this.cardElementLookup);

        cardElements.forEach(element => this[element].clear());
      },

      inDollars(priceInCents) {
        return priceInCents / 100;
      }
    }
  }
</script>
