// Utilities
import LayersControl from '../utilities/layers-control.js'
import LocalGeocoder from '../utilities/local-geocoder.js'

export const mixinMapboxInfo = {
  data() {
    return {
      mapboxInfo: {
        accessToken: document.getElementById('mapBoxToken').dataset.token,
        baseUrl: 'https://api.mapbox.com/geocoding/v5/mapbox.places'
      }
    }
  }
}

export const mixinMapbox = {
  data () {
    return {
      map: {},
      mapboxBaseConfig: {
        style: 'mapbox://styles/mapbox/streets-v9',
        pitchWithRotate: false,
        center: [0, 0],
        zoom: 2
      },
      mapboxControls: [],
      mapboxDrawConfig: {
        touchEnabled: false,
        controls: {
          combine_features: false,
          uncombine_features: false
        }
      }
    }
  },

  created () {
    const showSatelliteView = this.inModal
    const userInstance = this.user

    this.mapboxControls = [
      { type: new mapboxgl.NavigationControl(), position: 'bottom-right' },
      { type: new LayersControl({ userInstance, showSatelliteView }), position: 'bottom-left' },
    ]

    if (userInstance){
      if (userInstance.paidPlan || this.inModal) {
        this.mapboxControls.push({ type: new mapboxgl.ScaleControl(), position: 'bottom-left' })
      }
    }
  },

  mounted() {
    this.mountMap();
    this.addMapControls();
  },

  computed: {
    mapboxConfig() {
      return Object.assign({}, this.mapboxBaseConfig, { container: this.id })
    },

    disableDrawConfig() {
      return Object.assign({}, this.mapboxDrawConfig, { displayControlsDefault: false })
    }
  },

  methods: {
    mountMap() {
      mapboxgl.accessToken = this.mapboxInfo.accessToken

      this.map = new mapboxgl.Map(this.mapboxConfig)
    },

    addMapControls() {
      this.mapboxControls.forEach(control => {
        this.map.addControl(control.type, control.position)
      })
    },

    // Accept an object of callbacks to be used on different draw events
    addDrawControl(opts) {
      if (this.user) {
        if (this.user.paidPlan || this.inModal) {
          this.draw = new MapboxDraw(this.mapboxDrawConfig)
        } else {
          this.draw = new MapboxDraw(this.disableDrawConfig)
        }
      } else {
        this.draw = new MapboxDraw(this.disableDrawConfig)
      }

      this.map.addControl(this.draw, 'bottom-right')
      this.listenForDrawEvents(opts);
    },

    listenForDrawEvents(opts) {
      this.map.on('draw.delete', opts.onTrashClick)

      this.map.on('draw.create', (event) => opts.onDrawCreate(event.features[0]))
      this.map.on('draw.update', (event) => opts.onDrawUpdate(event.features[0]))
    },

    // Accept a callback that can be called when the geocoder provides a result
    addGeocoder(callback) {
      this.geocoderControl = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        localGeocoder: LocalGeocoder,
        flyTo: false,
        zoom: false
      })

      this.addGeocoderTooltip()
      this.listenForGeocoderResult(callback)
    },

    addGeocoderTooltip() {
      const tooltip = document.getElementById('geocoder-wrapper').firstChild

      tooltip.insertAdjacentElement('beforebegin', this.geocoderControl.onAdd(this.map))
    },

    listenForGeocoderResult(callback) {
      this.geocoderControl.on('result', (e) => {
        if (callback) { callback(e) }
      })
    }
  }
}
