import * as d3 from 'd3';
import axios from 'axios';

export const mixinCharts = {
  props: {
    id: { required: true, type: String },
    viewingMode: { required: true, type: String },
    endpoint: { required: true, type: String },
  },

  data() {
    return {
      isGettingData: true,
      chartData: [],
    }
  },

  watch: {
    viewingMode() {
      this.removeExistingChart();
      this.getChartConfig();
    }
  },

  computed: {
    shouldDisplayEmptyState() {
      return this.chartData.length < 1 && !this.isGettingData;
    },

    shouldDisplayChart() {
      return !this.shouldDisplayEmptyState;
    },

    selector() {
      return `#${this.id}`;
    },
  },

  mounted () {
    this.configureAxiosHeaders();
    this.businessId = document.body.dataset.businessId;
    this.getChartConfig();
  },

  methods : {
    configureAxiosHeaders() {
      const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content');
      axios.defaults.headers.common['X-CSRF-Token'] = csrf;
      axios.defaults.headers.common['Accept'] = 'application/json';
    },

    getChartConfig() {
      this.isGettingData = true;
      axios.get(`/b/${this.businessId}/${this.endpoint}?viewing_mode=${this.viewingMode}&ajax=true`)
        .then((response) =>   {
          this.isGettingData = false;
          this.chartData = response.data.chart_data;

          if (this.shouldDisplayChart) { this.renderChart(); };
        })
        .catch(console.error)
    },

    removeExistingChart() {
      d3.select(this.selector).select('svg').remove();
    },
  }
}
