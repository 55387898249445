<template>
  <div class="bg-grey-xlight">
    <div class="container">

      <div v-if="user.businessOwnerOrAdmin" class="dashboard--user-toggle-wrapper">
        <div >
          <p class="dashboard--header"> Viewing dashboard for {{ dashboardTypeName }}</p>
        </div>
        <div class="dashboard--mode-wrapper">
          <span
            :class="['margin-space-right-small', buttonClassForViewingMode('personal')]"
            @click="viewDashboard('personal')"
          >PERSONAL</span>
          <span
            :class="[{ 'margin-space-right-small': user.isAdmin }, buttonClassForViewingMode('business')]"
            @click="viewDashboard('business')"
          >ORGANISATION</span>
          <span
            v-if="user.isAdmin"
            :class="[buttonClassForViewingMode('admin')]"
            @click="viewDashboard('admin')"
          >ADMIN</span>
        </div>
      </div>

      <div :class="['dashboard--small-card-container--' + classForUserType]">
        <dashboard-card-small
          v-for="(card, cardIndex) in smallDashboardCards"
          :key="cardIndex"
          :card="card"
        ></dashboard-card-small>
      </div>

      <div class="dashboard--large-card-container">
        <bar-chart
          class="dashboard--chart-wrapper"
          :id="'bar-chart'"
          :endpoint="'projects_chart'"
          :viewingMode="viewingMode"
        >
        </bar-chart>
        <donut-chart
          class="dashboard--chart-wrapper"
          :id="'donut-chart'"
          :endpoint="'reports_chart'"
          :viewingMode="viewingMode"
        >
        </donut-chart>
      </div>

      <div class="dashboard--large-card-container--bottom">
        <tree-chart
          class="dashboard--chart-wrapper"
          :id="'tree-chart'"
          :endpoint="'sites_by_country'"
          :viewingMode="viewingMode"
        >
        </tree-chart>
        <sites-list
          class="dashboard--chart-wrapper"
          :viewingMode="viewingMode"
        >
        </sites-list>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DashboardCardSmall from './DashboardCardSmall.vue';
import BarChart from './BarChart.vue';
import DonutChart from './DonutChart.vue';
import SitesList from './SitesList.vue';
import TreeChart from './TreeChart.vue';

export default {

  components: {
    DashboardCardSmall,
    BarChart,
    DonutChart,
    SitesList,
    TreeChart
  },

  props: {
    smallCards: { required: true }
  },

  data: function () {
    return {
      viewingMode: 'personal',
      smallDashboardCards: this.smallCards
    }
  },

  computed: {
    dashboardTypeName () {
      if (this.viewingMode === 'admin') {
        return 'Administrators';
      } else if (this.viewingMode === 'business') {
        return this.user.business_name;
      } else {
        return this.user.first_name;
      }
    },

    classForUserType () {
      return this.user.businessOwnerOrAdmin ? 'owner' : 'personal';
    },

    isViewingPersonal () {
      return this.viewingMode === 'personal';
    },

    isViewingBusiness () {
      return this.viewingMode === 'business';
    },

    isViewingAdmin () {
      return this.viewingMode === 'admin';
    },

    user () {
      return this.$store.getters.user
    }
  },

  created () {
    const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
    axios.defaults.headers.common['X-CSRF-Token'] = csrf
    axios.defaults.headers.common['Accept'] = 'application/json'
  },

  mounted () {
    this.businessId = document.body.dataset.businessId
  },

  methods: {
    viewDashboard (viewingMode) {
      if (this.viewingMode === viewingMode) return;

      this.viewingMode = viewingMode;
      this.getDashboard();
    },

    buttonClassForViewingMode (viewingMode) {
      return this.viewingMode === viewingMode ? 'button--dashboard-selected' : 'button--dashboard';
    },

    getDashboard () {
      axios.get(`/b/${this.businessId}/dashboard?viewing_mode=${this.viewingMode}&ajax=true`)
        .then((response) => {
          this.smallDashboardCards = response.data.dashboard_config
        })
        .catch(console.error)
    }
  }
}
</script>
