<template>
    <div id="reports">
      <tabs :in-modal="false" class="tabs--small">
        <tab id="form-1" title="Reports" :active="true" class="tab--white">
          <div class="box--no-border-top box-padding">
            <page-wrapper
              :search="false"
              :pagination="true"
              name="report"
              :source="`/b/${this.businessId}/sites/${itemDup.id}/reports.json`"
              http-method="get">

              <page-reports></page-reports>
            </page-wrapper>
          </div>
        </tab>

        <tab id="form-2" title="GIS Downloads" class="tab--white">
          <div class="box--no-border-top box-padding">
            <page-wrapper
              :source="`/b/${this.businessId}/sites/${itemDup.id}/data_downloads.json`"
              name="GIS download"
              :pagination="true"
              http-method="get"
            >
              <page-my-downloads></page-my-downloads>
            </page-wrapper>
          </div>
        </tab>
      </tabs>
    </div>
</template>
<script>
  import axios from 'axios'
  import { mixinWebsockets } from '../../mixins/mixin-websockets.js'
  import FlashProvider from '../../utilities/flash-provider.js'

  import EditButton from '../buttons/EditButton.vue'
  import GeoCentroid from '../geo/GeoCentroid.vue'
  import Loader from '../loading/Loader';
  import MapboxSimple from '../map/MapboxSimple.vue'
  import ModalTrigger from '../modal/ModalTrigger.vue'
  import PageReports from '../pagination/PageReports.vue'
  import PageMyDownloads from '../pagination/PageMyDownloads'
  import PageWrapper from '../pagination/PageWrapper.vue'
  import RefreshButton from '../buttons/RefreshButton.vue'
  import ScrollToTrigger from '../scrollTo/ScrollToTrigger.vue'
  import Tooltip from '../tooltip/Tooltip.vue'
  import Tabs from '../tabs/Tabs.vue'
  import Tab from '../tabs/Tab.vue'
  import EditModal from '../modal/EditModal.vue'
  import EditField from '../form_fields/EditField.vue'

  export default {
    name: 'site-overview',

    components: {
      EditButton,
      GeoCentroid,
      Loader,
      MapboxSimple,
      ModalTrigger,
      PageMyDownloads,
      PageReports,
      PageWrapper,
      RefreshButton,
      ScrollToTrigger,
      Tab,
      Tabs,
      Tooltip,
      EditModal,
      EditField
    },

    mixins: [mixinWebsockets],

    props: {
      site: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        itemDup: this.site,
        websocketChannel: 'Site',
        updateMessage: `Site data for ${this.site.name} is updating`,
        completeMessage: `Site data for ${this.site.name} is up to date`,
        errorMessage: 'There was an error updating this site, please try again.',
        deletionMessage: "This project has been deleted, you are being returned to your site page.",
        businessId: null
      }
    },

    mounted() {
      this.businessId = document.body.dataset.businessId
      this.subscribeToChannel()
    },

    computed: {
      countryNotApplicable() {
        return this.itemDup.country_name === 'Not applicable';
      },

      portfolioName() {
        return this.itemDup.portfolios.map(portfolio => portfolio.name).join(', ')
      },

      options() {
        return this.site.site_types.map(site_type => { return { name: site_type.name, id: site_type.name}} );
      }
    },

    methods: {
      criticalClass(row) {
        return !row.critical ? ' icon--redlist-grey' : ''
      }
    }
  }

</script>
