 <template>
  <div>

    <modal-local v-if="isStep('upload') || isStep('processing')">
      <div class="flex flex-v-center flex-h-between margin-space-bottom">
        <h1 class="margin-space-bottom">We're importing your file</h1>
        <loader :width="40"></loader>
      </div>

      <div class="form--geospatial-upload--content relative">
        <div class="w-100">
          <div class="flex flex-v-center form--geospatial-upload--annotation">
            <img :src="require('images/icons/folder.svg')" width="22" class="margin-space-right-small">
            <span>{{ file.name }}</span>
          </div>
          <loading-bar
            :percentage="loadingPercentage"
            :height="6"
            :border="true"
            :color="'green'"
            style="width: 100%;">
          </loading-bar>
          <span class="form--geospatial-upload--annotation">{{textForStatus}} {{ loadingPercentage }}%</span>
        </div>
      </div>

      <p class="margin-space-top padding-top">Your file is currently {{ textForStatus.toLowerCase() }}, please do not navigate away from the page. Please note that the import of larger files may take some time.</p>
    </modal-local>

    <modal-local v-if="showConfirmModal">
      <h1 class="text-green">Confirm Import</h1>
      <p class="modal__intro margin-space-top">
        {{ sitesCount }} projects will be imported. Please click confirm to complete the process or cancel to abort.
      </p>

      <async-button v-if="!multipleSites" class="margin-space-right" @click="confirmImport" :disabled="isConfirming">Confirm</async-button>
      <button v-if="!multipleSites && !isConfirming" class="button--underline" @click.prevent="abortImport">Cancel</button>
      <div v-if="multipleSites">
        <div>
          <async-button class="margin-space-bottom w-100" @click="askPortfolioName" :disabled="isConfirming">Confirm and create portfolio</async-button>
        </div>
        <div>
          <async-button class="margin-space-bottom w-100" @click="confirmImport" :disabled="isConfirming">Confirm and skip portfolio</async-button>
        </div>
        <div class="center">
          <button v-if="!isConfirming" class="button--underline center w-100" @click.prevent="abortImport">Cancel</button>
        </div>
      </div>
    </modal-local>

    <modal-local v-if="showErrorModal">
      <h1 class="text-red">Upload Error</h1>
      <p class="margin-space-top">There was a problem processing your file.</p>
      <p v-html="errors.join(', ')"></p>

      <button class="button--cta margin-space-right" @click.prevent="resetUploadForm">OK</button>
      <a v-show="errorCsv" class="button--underline" @click="downloadErrorCsv" :href="errorCsvUrl" :download="errorCsvFilename">Download error CSV</a>
    </modal-local>
    <modal-local v-if="showPortfolioModal">
      <label for="report">Portfolio Name</label>
      <text-input
        name="portfolio"
        id="portfolio"
        :showValidationError="!isValidPortfolioName"
        classes="report-form"
        v-model="portfolioName">
      </text-input>

      <p class="form__submission">
        <async-button class="button--cta margin-space-right" @click="confirmImport" :disabled="isConfirming">Continue</async-button>
        <button v-if="!isConfirming" class="button--underline" @click.prevent="cancel">Cancel</button>
      </p>
    </modal-local>
    <div class="form--geospatial-upload">

      <div class="form--geospatial-upload--content relative">
        <clear-button
          v-show="isStep('confirm') || isStep('success') || isStep('fail')"
          @click="resetFileInput"
          position="top-right">
        </clear-button>
        <span v-if="isStep('input')"> No file selected</span>
        <div v-else-if="isStep('confirm')" class="flex flex-v-center">
          <img :src="require('images/icons/folder.svg')" width="22" class="margin-space-right-small">
          <span>{{ file.name }}</span>
        </div>
        <div v-else class="w-100">
          <div class="flex flex-v-center form--geospatial-upload--annotation">
            <img :src="require('images/icons/folder.svg')" width="22" class="margin-space-right-small">
            <span>{{ file.name }}</span>
          </div>
          <loading-bar
            :percentage="loadingPercentage"
            :height="6"
            :border="true"
            :color="'green'"
            style="width: 100%;">
          </loading-bar>
          <span v-if="isStep('upload')" class="form--geospatial-upload--annotation">Uploading {{ loadingPercentage }}%</span>
          <span v-if="isStep('processing')" class="form--geospatial-upload--annotation">Processing {{ loadingPercentage }}%</span>
          <span v-if="isStep('success')" class="text-green form--geospatial-upload--annotation">Complete!</span>
          <span v-if="isStep('fail')" class="text-red form--geospatial-upload--annotation">Error uploading file</span>
        </div>
      </div>

      <button @click.prevent="triggerFileSelect" class="button button--upload" :class="{ 'button--disabled': disableFileSelect }" :disabled="disableFileSelect">
        <span v-if="isStep('input') || isStep('confirm')" class="flex flex-v-center">
          <img :src="require('images/icons/folder-white.svg')" width="20" class="margin-space-right-small"> <span>Choose file <span class="text-10">&nbsp;max 25MB</span></span>
        </span>
        <span v-else class="flex flex-v-center">
          <loader color="white" :width="40"></loader> Processing
        </span>
      </button>

      <input @change="handleFileSelect" ref="fileInput" type="file" accept=".csv,.zip,.kml,.kmz,.xlsx" style="display: none;">
    </div>

    <div v-if="displayButtons" class="margin-space-top">
      <button @click.prevent="uploadFile" class="button button--cta margin-space-right" :class="{'button--disabled': disableUpload }" :disabled="disableUpload">Upload</button>
      <button @click.prevent="cancel" class="button button--underline">Cancel</button>
    </div>
    <p v-else class="margin-space-top bold">Your file is being processed, please do not navigate away from the page.</p>
  </div>
</template>

<script>
import AsyncButton from '../buttons/AsyncButton'
import ClearButton from '../buttons/ClearButton'
import Loader from '../loading/Loader'
import LoadingBar from '../loading/LoadingBar'
import { eventHub } from '../../ibat.js'
import axios from 'axios'
import FlashProvider from '../../utilities/flash-provider.js'
import { mixinWebsockets } from '../../mixins/mixin-websockets.js'
import ModalLocal from '../modal/ModalLocal.vue'
import TextInput from '../form_fields/TextInput'
import { mixinValidate } from '../../mixins/mixin-validate'

export default {
  mixins: [mixinWebsockets, mixinValidate],

  components: { AsyncButton, ClearButton, Loader, LoadingBar, ModalLocal, TextInput },

  data() {
    return {
      file: null,
      possibleSteps: {
        'input': 0,
        'confirm': 1,
        'upload': 2,
        'processing': 3,
        'success': 4,
        'fail': 5
      },
      step: 'input',
      loadingPercentage: 0,
      showConfirmModal: false,
      showErrorModal: false,
      showPortfolioModal: false,
      sitesCount: null,
      errors: [],
      errorCsv: null,
      errorCsvUrl: null,
      errorCsvFilename: null,
      websocketChannel: 'GeospatialUpload',
      itemDup: null,
      isConfirming: false,
      portfolioName: '',
      regex: /\/|\\/
    }
  },

  destroyed() {
    if (this.subscription) { this.subscription.unsubscribe(); };
    this.cable.disconnect();
  },


  computed: {
    displayButtons() {
      return this.isStep('input') || this.isStep('confirm')
    },

    disableFileSelect() {
      return this.isStep('upload') || this.isStep('processing') || this.isStep('success') || this.isStep('fail')
    },

    disableUpload() {
      return this.isConfirming || this.isStep('input') || this.isStep('upload') || this.isStep('success') || this.isStep('fail')
    },

    textForStatus() {
      return this.isStep('upload') ? 'Uploading' : 'Processing';
    },

    multipleSites() {
      if (this.sitesCount > 1) {
        return true
      } else {
        return false
      }
    },

    isValidPortfolioName() {
      return this.isValidLength(this.portfolioName, 1)
      && this.isValidString(this.portfolioName, this.regex)
    },
  },

  mounted () {
    this.businessId = document.body.dataset.businessId
  },

  methods: {
    resetUploadForm() {
      this.resetFileInput()
      this.showConfirmModal = false
      this.showErrorModal = false
      this.itemDup = null
      this.sitesCount = null
      this.errorCsv = null
      this.errorCsvUrl = null
      this.errorCsvFilename = null
      if (this.subscription) { this.subscription.unsubscribe(); };
      this.toggleNavigationPrevention(false)
      this.isConfirming = false
    },

    confirmImport() {
      this.isConfirming = true

      axios.put(`/b/${this.businessId}/geospatial_uploads/${this.itemDup.id}`, { commit: 'confirm', portfolio_name: this.portfolioName })
        .then(res => {
          Turbo.visit(`/b/${this.businessId}/sites`)
        })
        .catch(error => {
          FlashProvider.display(error.response.data.message, 'alert', 60000)
          this.resetUploadForm()
        })
    },

    abortImport() {
      axios.put(`/b/${this.businessId}/geospatial_uploads/${this.itemDup.id}`, { commit: 'abort' })
        .then(res => {
          this.resetUploadForm()
          FlashProvider.display(res.data.message, 'notice', 60000)
        })
        .catch(error => {
          FlashProvider.display(error.response.data.message, 'alert', 60000)
          this.resetUploadForm()
        })
    },

    onReceived(data) {
      if (data.errors.length > 0) {
        this.displayErrorModal(data.errors, data.error_csv)
      } else {
        this.loadingPercentage = Math.round((data.percentage_complete / 2) + 50)

        if (data.success && (this.loadingPercentage >= 100)) {
          this.step = 'success'
          this.sitesCount = data.count
          this.showConfirmModal = true
        }
      }
    },

    isStep(step) {
      return this.step === step
    },

    displayErrorModal(errors, errorCsv = null) {
      this.step = 'fail'
      this.loadingPercentage = 0
      this.errors = errors
      this.errorCsv = errorCsv
      this.showErrorModal = true
    },

    resetFileInput() {
      this.file = undefined
      this.step = 'input'
      this.loadingPercentage = 0
      this.$refs.fileInput.value = ''
    },

    askPortfolioName() {
      this.showConfirmModal = false
      this.showPortfolioModal = true
    },

    triggerFileSelect() {
      this.resetFileInput()
      this.$refs.fileInput.click()
    },

    handleFileSelect(event) {
      const file = event.target.files[0];

      if (file) {
        this.file = file;
        const maxByteSize = 25e+6;

        if (file.size > maxByteSize) {
          const message = `Your file is too large. The maximum file size is ${Math.round(maxByteSize / 1000000)}MB but your file is ~${Math.round(file.size / 1000000)}MB.`
          this.displayErrorModal([message])
        } else {
          this.step = 'confirm'
        }
      }
    },

    toggleNavigationPrevention(boolean) {
      const event = boolean ? () => { return '' } : null;

      window.onbeforeunload = event
    },

    uploadFile() {
      this.step = 'upload'

      let formData = new FormData()
      formData.append('file', this.file)

      const self = this
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: function(progressEvent) {
          const percentCompleted = Math.round(((progressEvent.loaded * 100) / progressEvent.total) / 2);
          self.loadingPercentage = percentCompleted
        }
      };

      this.toggleNavigationPrevention(true)

      axios.post(`/b/${this.businessId}/geospatial_uploads`, formData, config)
        .then(res => {
          this.step = 'processing'
          this.itemDup = res.data.upload
          this.subscribeToChannel()
        })
        .catch(error => {
          this.displayErrorModal([error.response.data.message])
        })
    },

    downloadErrorCsv () {
      const encodedCsv = encodeURIComponent(this.errorCsv)
      this.errorCsvUrl = `data:text/csv;charset=utf-8,${encodedCsv}`
      this.errorCsvFilename = `errors_for_${this.file.name}.csv`
    },

    cancel () {
      eventHub.$emit('requestModalClose', 'create-site')
    },
  }
}
</script>
