<template>
  <div>
    <div>
      <label>Select Site Type </label>
      <select v-model="selectedSiteType">
        <option v-for="siteType in siteTypes" :key="siteType" :value="siteType">{{ siteType }}</option>
      </select>
    </div>
    <p class="form__submission">
    <button @click="addSiteType" :disabled="!isValidSiteType" :class="`button--outline button--cta margin-space-right  ${isValidSiteType ? '' : 'button--disabled'}`">Add To Sites</button>

    <button @click="cancel" class="button--underline">Cancel</button>
    </p>
  </div>
</template>

<script>
import { eventHub } from '../../ibat.js'
import FlashProvider from '../../utilities/flash-provider'
import axios from 'axios'

export default {
  name: 'form-add-site-type',

  data() {
    return {
      selectedSiteType: null,
      seletedSitesIds: null
    };
  },
  props: {
    siteTypes: {
      type: Array,
      required: true
    }
  },
  computed: {
    isValidSiteType() {
      return this.selectedSiteType !== null
    },
    selectedSiteIds() {
      return this.$store.state.pagination.selectedItems
    }
  },
  
  mounted () {
    this.businessId = document.body.dataset.businessId
  },

  methods: {
    resetForm() {
      this.selectedSiteType = null
    },

    addSiteType() {
      axios.patch(`/b/${this.businessId}/sites/update_sites`, {
        site_ids: this.selectedSiteIds,
        site_type: this.selectedSiteType
      })
      .then(response => {
        FlashProvider.display(response.data.message)
        eventHub.$emit('getNewItems')
        this.cancel()
      })
      .catch(error => {
        console.error(error);
        FlashProvider.display(error.response.data.message, 'alert')
      });
    },

    cancel() {
      eventHub.$emit('requestModalClose', 'add-site-type')
      this.selectedSiteType = null
    },
  }
};
</script>
