<template>
  <div class="page-padding--bottom container">
    <edit-modal></edit-modal>
    <modal :modal-id="modalId" modal-classes="modal--delete">
      <p>Are you sure you want to delete this portfolio?</p>
      <div>
        <p class="bold text-red">{{ errorMessage }}</p>
      </div>
      <div class="flex flex-center">
        <button class="button--outline margin-space-right" @click="closeModal">Cancel</button>
        <button @click="deleteItems(true)" class="button--cta margin-space-right">Yes</button>
      </div>
    </modal>
    <div class="flex flex-wrap section-padding">
      <div class="flex-2-fiths flex-s-whole" style="min-width: 400px;">
        <div class="flex">
          <h1 class="ellipsis heading--simple--no-margin no-margin--top">{{ itemDup.name }}</h1>
          <div class="margin-space-left-small margin-space-right">
            <edit-button
              title="Edit the name of portfolio"
              attribute="name"
              label="Edit Portfolio Name"
              :password-required="false"
              :on-close="(newName) => { this.itemDup.name = newName }"
              :url="`/b/${this.businessId}/portfolios/${itemDup.id}`"
              resource="portfolio"
              size="small"
            ></edit-button>
          </div>
        </div>
        <div class="no-margin--top">
          <ul class="ul-unstyled ul-margin">
            <li>
              <span><strong>Number of projects: </strong>{{ itemDup.sites.length }}</span>
            </li>
            <li>
              <span><strong>Created by: </strong>{{ itemDup.createdBy }}</span>
            </li>
          </ul>
        </div>
        <div class="flex-inline flex-column margin-space-bottom">
          <a :href="`/b/${this.businessId}/portfolios/${itemDup.id}/reports/new`" class="button--cta margin-space-bottom-small">New Report</a>
          <modal-trigger id="portfolio-sites" modal-id="portfolio-sites" class="button--cta margin-space-bottom-small">Add Site(s)</modal-trigger>
          <modal-trigger id="delete-portfolio" modal-id="delete-portfolio" class="button--cta-delete margin-space-bottom-small">Delete Portfolio</modal-trigger>
        </div>
      </div>
    </div>
    <div id="reports">
      <tabs :in-modal="false" class="tabs--small">
        <tab id="form-1" title="Sites" :active="true" class="tab--white">
          <div class="box--no-border-top box-padding">
            <page-wrapper
              :search="false"
              :pagination="true"
              name="portfolio"
              :item-id=itemDup.id
              :sites-to-update="getUpdatingSites"
              :is-portfolio=true
              :can-delete=true
              :source="`/b/${this.businessId}/portfolios/${itemDup.id}/sites.json`"
              http-method="get">
              <page-sites
                :can-delete=true
                :isPortfolio=true
              >
              </page-sites>
            </page-wrapper>
          </div>
        </tab>
        <tab id="form-2" title="Multisite Reports" class="tab--white">
          <div class="box--no-border-top box-padding">
            <page-wrapper
              :search="false"
              :pagination="true"
              name="report"
              :source="`/b/${this.businessId}/portfolios/${itemDup.id}/reports/multi_site.json`"
              http-method="get">
              <page-reports></page-reports>
            </page-wrapper>
          </div>
        </tab>
        <tab id="form-3" title="Disclosure Reports" class="tab--white">
          <div class="box--no-border-top box-padding">
            <page-wrapper
              :search="false"
              :pagination="true"
              name="report"
              :source="`/b/${this.businessId}/portfolios/${itemDup.id}/reports/tnfd.json`"
              http-method="get">
              <page-reports></page-reports>
            </page-wrapper>
          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import { eventHub } from '../../ibat.js'
  import EditButton from '../buttons/EditButton.vue'
  import EditModal from '../modal/EditModal.vue'
  import Modal from '../modal/Modal.vue'
  import ModalTrigger from '../modal/ModalTrigger.vue'
  import Tabs from '../tabs/Tabs.vue'
  import Tab from '../tabs/Tab.vue'
  import PageSites from '../pagination/PageSites.vue'
  import PageReports from '../pagination/PageReports.vue'
  import PageWrapper from '../pagination/PageWrapper.vue'
  import FlashProvider from '../../utilities/flash-provider.js'
  export default {
    name: 'show-portfolio',
    components: {
      EditButton,
      EditModal,
      Modal,
      ModalTrigger,
      Tab,
      Tabs,
      PageWrapper,
      PageSites,
      PageReports
    },
    props: {
      portfolio: {
        type: Object,
        required: true
      },
      siteUrl: {
        type: String
      }
    },
    data() {
      return {
        itemDup: this.portfolio,
        errorMessage: '',
        modalId: 'delete-portfolio',
        businessId: null
      }
    },
    computed: {
      getUpdatingSites () {
        return this.itemDup.sites.filter((site) => {
          return site.requires_update === 'true'
        })
      }
    },
    mounted () {
      this.businessId = document.body.dataset.businessId
    },
    methods: {
      deleteItems () {
        let action = `/b/${this.businessId}/portfolios/${this.itemDup.id}`
        axios.delete(action)
        .then((response) => {
          this.closeModal();
          window.location.href = this.siteUrl
          FlashProvider.display(response.data.message)
        })
        .catch((error) => {
          if (error.response) {
            this.deleteError = true
            this.errorMessage = error.response.data.message
          }
        })
      },
      closeModal () {
        eventHub.$emit('requestModalClose', this.modalId)
      }
    },
  }
</script>
