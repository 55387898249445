import axios from 'axios'
import FlashProvider from '../utilities/flash-provider'
import { eventHub } from '../ibat'
import { mixinValidate } from './mixin-validate'

export const mixinFormPortfolio = {

  mixins: [mixinValidate],

  data() {
    return { submitting: false }
  },

  created() {
    this.configureAxiosHeaders()
  },

  mounted () {
    this.businessId = document.body.dataset.businessId
  },

  methods: {
    updatePortfolio() {
      if (this.formComplete) {
        let portfolio_id = this.formFields ? this.formFields.id : this.selectedPortfolio
        axios.put(`/b/${this.businessId}/portfolios/${portfolio_id}`, { portfolio: this.updatePortfolioSiteParams })
          .then(response => {
            this.submitting = false
            FlashProvider.display(response.data.message)
            eventHub.$emit('getNewItems')
            this.cancel()
          })
          .catch(error => {
            this.submitting = false
            FlashProvider.display(error.response.data.message, 'alert')
          })
      }
    },

    createPortfolio() {
      if (this.formComplete) {
        axios.post(`/b/${this.businessId}/portfolios`, { portfolio: this.portfolioSiteParams })
          .then(response => {
            this.submitting = false
            FlashProvider.display(response.data.message)
            eventHub.$emit('requestModalClose', 'create-portfolio')
            eventHub.$emit('updatePortfolioFilter', response.data.portfolio)
          })
          .catch(error => {
            this.submitting = false
            FlashProvider.display(error.response.data.message, 'alert')
          })
      }
    },

    cancelCreatePortfolio() {
      eventHub.$emit('requestModalClose', 'create-portfolio')
    },

    cancel() {
      eventHub.$emit('requestModalClose', 'portfolio-sites')
      eventHub.$emit('requestModalClose', 'add-to-portfolio')
    },

    configureAxiosHeaders() {
      const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
      axios.defaults.headers.common['X-CSRF-Token'] = csrf
      axios.defaults.headers.common['Accept'] = 'application/json'
    }
  }
}
