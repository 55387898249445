<template>
  <div class="chart-wrapper card--dashboard-large--left bg-white">
    <p v-if="!isGettingData && !shouldDisplayEmptyState" class="margin-space-top margin-space-left no-margin--bottom light">{{ totalSitesCount }} projects in {{ countriesCount }} countries</p>
    <div :class=" { 'tree-chart--wrapper': !isGettingData && !shouldDisplayEmptyState }">
      <div id="tree-chart"></div>
    </div>
    <empty-state-message
      v-if="shouldDisplayEmptyState"
      lineOne="Country data can't be displayed until you have projects"
      lineTwo="Click here to create some"
      :link="`/b/${this.businessId}/sites`">
    </empty-state-message>
    <loader v-if="isGettingData" :width="100" color="light-grey"></loader>
  </div>
</template>

<script>
  import axios from 'axios';
  import * as d3 from 'd3';

  import Loader from '../loading/Loader';
  import EmptyStateMessage from '../empty_state/EmptyStateMessage';

  import { mixinCharts } from'../../mixins/mixin-charts.js';

  export default {
    components: {
      Loader,
      EmptyStateMessage
    },

    mixins: [mixinCharts],

    data: function() {
      return {
        elementsList: [],
        rectangles: null,
        width: 546,
        height: 350,
        margin: 10,
        chartColors: ["#efb856", "#dd6280", "#5680d2", "#40a0be", "#35b7a6", "#8380cf", "#b570c3", "#e46851"],
        colorIndex: 0,
        businessId: null,
      }
    },

    mounted() {
      this.businessId = document.body.dataset.businessId;
    },

    computed: {
      isLastColor() {
        return this.colorIndex === (this.chartColors.length - 1);
      },

      countriesCount() {
        return this.chartData.length;
      },

      totalSitesCount() {
        return this.chartData.reduce((total, group) => {
          return total + group.total;
        }, 0);
      }
    },

    methods: {
      renderChart() {
        this.resetColorIndex();
        this.createSvg();
        this.computeTreeMapData()
        this.buildTreeMap()
        this.appendTooltip();
        this.addEventListeners();
      },

      resetColorIndex() {
        this.colorIndex = 0;
      },

      createSvg() {
        this.svg = d3.select(this.selector)
          .append("svg")
            .attr('class', 'chart--svg')
            .attr('xmlns', 'http://www.w3.org/1999/xhtml')
            .attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
            .attr('preserveAspectRatio', 'xMidYMid meet')
          .append("g")
      },

      computeTreeMapData() {
        const data = { children: this.chartData };
        const root = d3.hierarchy(data).sum(d => d.total);

        // Then d3.treemap computes the position of each element of the hierarchy, then the coordinates are added to the root object
        d3.treemap().size([this.width, this.height])(root)

        this.root = root;
      },

      buildTreeMap() {
        this.addRectangles();
        this.addTitles();
        this.addCounts();
        this.createElementsList();
      },

      addRectangles() {
        this.rectangles = this.svg
          .selectAll("rect")
          .data(this.root.leaves())
          .enter()
          .append("rect")
            .attr('opacity', 1)
            .attr('x', d => d.x0)
            .attr('y', d => d.y0)

        // Add initial transition
        this.rectangles.transition()
            .duration(500)
            .attr('width', d => d.x1 - d.x0)
            .attr('height', d => d.y1 - d.y0)
            .attr('fill', this.getChartColor)
      },

      addTitles() {
        this.titles = this.svg.selectAll(".country-name-label")
          .data(this.root.leaves())
          .enter()
          .append("svg:text")
          .attr('class', 'country-name-label')
          .style("pointer-events", "none")
            .attr('opacity', 0)
            .attr("x", d => d.x0 + 10)    // +10 to adjust position (more right)
            .attr("y", d => d.y0 + 20)    // +20 to adjust position (lower)
            .text(d => {
              const rectWidth = d.x1 - d.x0;
              return rectWidth > 45 ? d.data.country_iso3 : ''
            })
            .attr("font-size", "15px")
            .attr("fill", "black")

        // Add initial transition
        this.titles.transition().duration(500).attr('opacity', 1)
      },

      addCounts() {
        this.counts = this.svg
          .selectAll(".country-counts-label")
          .data(this.root.leaves())
          .enter()
          .append("svg:text")
          .attr('class', ".country-counts-label")
          .style("pointer-events", "none")
          .attr('opacity', 0)
          .attr("x", d => d.x0+10)    // +10 to adjust position (more right)
          .attr("y", d => d.y1-10)    // +20 to adjust position (lower)
          .text(d => {
              const rectWidth = d.x1 - d.x0;
              const rectHeight = d.y1 - d.y0;
              return (rectHeight > 45 && rectWidth > 45) ? d.data.total : ''
            })
          .attr("font-size", "15px")
          .attr("fill", "black")

        // Add initial transition
        this.counts.transition().duration(500).attr('opacity', 1)
      },

      createElementsList() {
        this.elementsList = [this.rectangles, this.titles, this.counts];
      },

      appendTooltip() {
        this.tooltip = d3.select("body").append("div").attr("class", "tree-chart--tooltip");
      },

      addEventListeners() {
        this.addTooltipEvents();
        this.addHoverEvents();
      },

      addTooltipEvents() {
        this.rectangles.on('mousemove', (d) => {
          this.tooltip.style("left", d3.event.pageX + 10 + "px")
          this.tooltip.style("top", d3.event.pageY - 20 + "px")
          this.tooltip.style("display", "inline-block");
          this.tooltip.html(`<span class="tree-chart--tooltip-title">${d.data.country_name} - ${d.data.country_iso3}</span><br/>${d.data.total} sites<br/>${d.data.percentage}% of total`)
        })
        .on("mouseout", d => this.tooltip.style("display", "none"));
      },

      addHoverEvents() {
        ['mouseover', 'mouseleave'].forEach((mouseEvent) => {
          this.rectangles.on(mouseEvent, this.iterateThroughElements(mouseEvent))
        });
      },

      alterOpacity(hoveredCountry, mouseEvent) {
        return function(elementType) {
          return elementType
            .transition()
            .duration(500)
            .attr('opacity', (country) => {
              return ((mouseEvent === 'mouseleave') || (country === hoveredCountry)) ? 1 : 0.7;
            });
        }
      },

      iterateThroughElements(mouseEvent) {
        const self = this;

        return function(hoveredCountry) {
          return self.elementsList.forEach(self.alterOpacity(hoveredCountry, mouseEvent))
        };
      },

      getChartColor() {
        const color = this.chartColors[this.colorIndex];

        this.isLastColor ? this.colorIndex = 0 : this.colorIndex += 1;

        return color;
      },
    }
  }
</script>
