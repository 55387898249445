<template>
  <div :class="['chart-wrapper', 'card--dashboard-large--right', 'bg-white']">
    <div v-if="!isGettingData && !shouldDisplayEmptyState" class="sites-list--wrapper">
      <div class="sites-list--header">
        <div class="sites-list--item-name--header"><span>Recent Sites</span></div>
        <div class="sites-list--item-wdpa--header"><tooltip :text="wdpaTooltipText"><span>PAs</span></tooltip></div>
        <div class="sites-list--item-rl--header"><tooltip :text="redlistTooltipText"><span>Red List</span></tooltip></div>
        <div class="sites-list--item-kba--header"><tooltip :text="kbaTooltipText"><span>KBAs</span></tooltip></div>
        <div class="sites-list--item-created--header"><span>Created</span></div>
        <div class="sites-list--item-chevron--header"></div>
      </div>
      <div v-for="site in mostRecentSites" class="sites-list--item" @click="navigateToSite(site.siteUrl)">
        <div class="sites-list--item-name">{{ site.name }}</div>
        <div class="sites-list--item-wdpa">{{ site.pa }}</div>
        <div class="sites-list--item-rl">{{ site.redList }}</div>
        <div class="sites-list--item-kba">{{ site.kba }}</div>
        <div class="sites-list--item-created">{{ site.created }}</div>
        <div class="sites-list--item-chevron"><i class="ul__icon icon-chevron-right"></i></div>
      </div>
    </div>
    <empty-state-message
      v-if="shouldDisplayEmptyState"
      lineOne="You don't have any projects yet"
      lineTwo="Click here to create some"
      :link="`/b/${this.businessId}/sites`">
    </empty-state-message>
    <loader v-if="isGettingData" :width="100" color="light-grey"></loader>
  </div>
</template>

<script>
  import axios from 'axios';

  import Loader from '../loading/Loader';
  import EmptyStateMessage from '../empty_state/EmptyStateMessage';
  import Tooltip from './../tooltip/Tooltip';
  import { TooltipTexts } from '../../mixins/mixin-tooltip-texts';

  export default {
    components: {
      EmptyStateMessage,
      Loader,
      Tooltip,
    },

    mixins: [TooltipTexts],

    props: {
      viewingMode: { required: true, type: String }
    },

    data: function() {
      return {
        isGettingData: true,
        mostRecentSites: [],
        businessId: null
      }
    },

    watch: {
      viewingMode() {
        this.removeExistingData();
        this.getChartConfig();
      }
    },

    computed: {
      shouldDisplayEmptyState() {
        return this.mostRecentSites.length < 1 && !this.isGettingData
      },

      backgroundColor() {
        return this.shouldDisplayEmptyState ? 'bg-white' : 'bg-transparent';
      }
    },

    mounted() {
      this.configureAxiosHeaders();
      this.businessId = document.body.dataset.businessId;
      this.getChartConfig();
    },

    methods : {
      configureAxiosHeaders: function() {
        const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
        axios.defaults.headers.common['X-CSRF-Token'] = csrf
        axios.defaults.headers.common['Accept'] = 'application/json'
      },

      getChartConfig: function() {
        this.isGettingData = true;

        axios.get(`/b/${this.businessId}/dashboard_sites_list?viewing_mode=${this.viewingMode}&ajax=true`)
          .then((response) => {
            this.mostRecentSites = response.data.dashboard_sites_list
            this.isGettingData = false
          })
          .catch(console.error)
      },

      navigateToSite: function(siteUrl) {
        window.location.href = siteUrl
      },

      removeExistingData() {
        this.mostRecentSites = [];
      },
    }
  }
</script>
